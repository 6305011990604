import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body single-page" }
const _hoisted_4 = { class: "heading" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.page.title), 1),
        (_ctx.page.main_image)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "img-fluid",
              src: _ctx.site_url +'/'+ _ctx.page?.main_image
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "content",
          innerHTML: _ctx.page.content_data
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}